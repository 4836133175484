<template>
  <form-suppliers
    title="Formularios de Proveedores"
    :showActionCreate="false"
  ></form-suppliers>
</template>

<script>
import FormSuppliers from "@/router/views/supplying/formSuppliers/index.vue";
export default {
  components: {
    FormSuppliers,
  },
};
</script>

<style></style>
